<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="包邮金额" prop="money">
        <el-input type="number" v-model="form.money" size="small">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="包邮地区" prop="region">
        <el-select v-model="form.region" multiple placeholder="请选择" style="width: 100%">
          <el-option
              v-for="item in apiRegionList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="参与会员" prop="level">
        <el-checkbox-group v-model="form.level" size="small">
          <el-checkbox
              v-for="role in levelArr"
              :key="role.id"
              :label="role.id"
          >
            {{ role.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiGetAreaSelect, apiGetUserLevelList, apiUpdatePackageMail} from "@/request/api";

export default {
  name: 'Edit',
  data() {
    return {
      apiRegionList: [],
      levelArr: [],
      form: {
        id: '',
        money: '',
        region: [],
        level: [],
      },
      rules: {
        money: [
          {required: true, trigger: 'blur', message: '包邮金额不能为空'},
        ],
        region: [
          {required: true, trigger: 'blur', message: '包邮地区不能为空'},
        ],
        level: [
          {required: true, trigger: 'blur', message: '参与会员不能为空'},
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
    this.getLevelList()
    this.requestRegion()
  },
  methods: {
    //获取用户等级列表(不分页)
    getLevelList() {
      apiGetUserLevelList().then(res => {
        if (res.code == 200) {
          this.levelArr = res.data
        }
      })
    },
    // 获取省份
    requestRegion() {
      apiGetAreaSelect().then(res => {
        if (res.code == 200) {
          this.apiRegionList = res.data
        }
      })
    },
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        const tempObj = Object.assign({}, row)
        this.form = {
          id: tempObj.id,
          money: tempObj.money,
          region: tempObj.region.map(Number),
          level: tempObj.level.map(Number),
        }
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    //保存
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiUpdatePackageMail(this.form).then(res => {
            if (res.code == '200') {
              this.$message.success(res.message)
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message.error(res.message)
              this.close()
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
