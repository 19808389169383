<template>
  <el-card class="box-card">
    <vab-query-form-left-panel :span="24">
      <el-button class="button" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">新增</el-button>
      <!--      <el-button @click="fullPackageSettings" type="danger" size="small">满额包邮设置</el-button>-->
      <span style="margin-left: 20px">模块开关:</span>
      <el-radio-group v-model="form.type" size="small" @change="submit">
        <el-radio-button :label="0">暂不启用</el-radio-button>
        <el-radio-button :label="1">启用</el-radio-button>
      </el-radio-group>
    </vab-query-form-left-panel>

    <el-table
        v-loading="listLoading"
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="money"
          label="包邮金额">
      </el-table-column>
      <el-table-column
          align="center"
          prop="regionName"
          label="包邮地区"
          :show-overflow-tooltip="true"
      >
        <template #default="{ row }">
          <span v-for="(item,index) in row.regionName" :key="index" class="site">
            {{ item.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="levelName"
          label="参与会员等级"
          :show-overflow-tooltip="true"
      >
        <template #default="{ row }">
          <span v-for="(item,index) in row.levelName" :key="index" class="site">
            {{ item.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="添加时间"
          prop="create_at"
          show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.create_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        align="center"
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit" @fetch-data="fetchData"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiDeletePackageMail, apiGetPackageMail, apiSetGlobalPackage} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      activeName: '1',
      selectRows: '',
      listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
      },
      form: {
        type: '',
      },
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    submit(event) {
      apiSetGlobalPackage({type: event}).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
        this.fetchData()
      })
    },
    fullPackageSettings() {
      this.$router.push({
        path: 'fullPackageSettings',
      })
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeletePackageMail({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetPackageMail(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
          this.form.type = parseInt(res.data.conf)
        }
      })
      this.listLoading = false
    },
  }
}

</script>
<style>
.button {
  margin-top: 20px;
}

.site {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  color: #409eff;
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #409eff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 5px;
}
</style>
